/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// ** import variables
@import "./variables/variables";

// ** General **//
.content.app-content {
  padding: 0 !important;
}

// Sections styles
.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

// full width container
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

// container padding
.container {
  max-width: 100%;
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}

.fit-cover {
  object-fit: cover !important;
}

.z-index-9 {
  z-index: 9 !important;
}

// to read \n when text come from backend
.text-break__pre-line {
  white-space: pre-line;
}

//** General End **//

// ** Navbar Start **\\

.header-navbar {
  transition: 0.3s;

  li {
    a {
      color: $secondary;
    }
  }
}

.header-navbar__transparent {
  transition: 0.3s;
  background-color: transparent !important;

  li {
    a {
      color: white !important;
      svg {
        color: white !important;
      }
    }
  }

  .logo__energy {
    fill: white !important;
  }

  .dropdown-item {
    color: $secondary !important;
    svg {
      color: $secondary !important;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: $primary !important;
    svg {
      color: $primary !important;
    }
  }
  .dropdown-item:active {
    color: white !important;
    svg {
      color: white !important;
    }
  }
}

.nav-link-style.active {
  color: $primary !important;
  font-weight: bold;
}

.navbar-brand {
  margin-top: 0.75rem !important;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 100px;
}
.auth-wrapper .auth-inner .brand-logo img {
  width: 150px;
}

.header-navbar__transparent.header-navbar.navbar-shadow {
  box-shadow: unset;
}

@media (min-width: 768px) {
  .container,
  .navbar-container {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
}

// ** Navbar End **\\

// ** App ad Start ** \\

.app-ad-content {
  background-color: $primary;
}

@media (min-width: 768px) {
  .app-ad-content {
    left: 70% !important;
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .app-ad-content {
    p {
      font-size: 1.5rem;
      line-height: 1.3;
    }

    h5 {
      font-size: 1.5rem;
    }
  }
}
.app-add__store-btn {
  border: 0 !important;
  height: 35px;

  img {
    width: 100%;
    height: 100%;
  }
}

.solar-modal__app-btn,
.download-app-modal__app-btn {
  border: 0 !important;
  height: 45px;
  width: 135px;

  img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .app-add__store-btn {
    width: 25%;
    height: 30px;
  }
}

@media (min-width: 992px) {
  .app-add__store-btn {
    height: 45px;
  }
}

.h-400 {
  height: 400px !important;
}

@media (max-width: 768px) {
  .h-sm-400 {
    height: 400px !important;
  }
}
// ** App ad End ** \\

// ** Banner Start ** \\
.image-banner__container {
  position: relative;
  width: 100%;
  height: 65vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.carousel-banner__container {
  position: relative;
  width: 100%;
  height: 65vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  white-space: pre-line;
}
// ** Banner End ** \\

@media (min-width: 992px) {
  .content-detached.content-left .content-body {
    margin-right: calc(260px + 2rem) !important;
  }
}

.breadcrumb {
  padding-left: 0px !important;
}

// ** Blog Start ** \\
.blog-card__img-container {
  height: 350px;
  img {
    height: 100%;
    object-fit: cover;
  }
}
.blog-details__img-container {
  height: 400px;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.blog-list-wrapper {
  .blog-title-truncate {
    height: 4.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .blog-content-truncate {
    height: 5rem;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .blog-title-truncate {
      height: 7rem !important;
    }

    .blog-content-truncate {
      height: 8rem !important;
    }
  }
}

.blog-sidebar__img-container {
  width: 100px;
  height: 70px;
}
// ** Blog  End ** \\

// ** Facebook post Start ** \\

.facebook-title-truncate {
  height: 4.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .facebook-title-truncate {
    height: 7rem !important;
  }
}

.facebook-card__img-container {
  height: 350px;
  img {
    height: 100%;
    object-fit: cover;
  }
}
// ** Facebook post End ** \\

// ** Footer Start ** \\
.footer {
  background-color: #080808;
  color: white;
  a,
  h3 {
    color: white;
  }
}

.top-footer {
  margin-top: 40px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.322);
  svg {
    color: $primary;
    margin-right: 0.75rem;
  }
}

.footer__latest-news-container {
  height: 80px;
}

.footer__last-news-img-container {
  min-width: 80px;
  max-width: 80px;
  min-height: 100%;
  max-height: 100%;

  margin-right: 0.75rem;

  img {
    width: 100%;
    height: 80px;
    object-fit: cover;
  }
}

.footer__last-news-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

// ** Footer End ** \\

// ** How it works Start **\\
.how-it-works__steps__img-container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.how-it-works__title-number {
  min-width: 30px;
  height: 30px;
  background-color: $primary;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-weight: 600;
  }
}
// ** How it works End **\\

// ** Registration Code Input Start **\\

.registration__code-input {
  font-size: 1.5rem;
  width: 55px;
  border-radius: 10px;
  border: 1px solid #666;
  outline: 0;
  transition: 0.3s;
}

.registration__code-input:focus {
  border: 1px solid $primary;
  box-shadow: 0 3px 10px 0rgba (34, 41, 47, 0.1);
  transition: 0.3s;
}

// ** Registration Code Input End **\\

// ** Appliance Card Start **\\

.appliance-card {
  height: 17rem;

  .appliance-card__icon-container {
    height: 7rem;
    width: 100%;
    img {
      height: 100%;
      max-width: 180px;
      max-height: 90px;
    }
  }

  .appliance-card__label-container {
    height: 2.5rem;
    display: flex;
    align-items: flex-end;
    p {
      line-height: 1.2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .appliance-card__title-container {
    height: 2.5rem;
    display: flex;
    align-items: center;
    h5 {
      line-height: 1.2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0;
    }
  }

  .appliance-card__values-container {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.appliance-quantity-card__card {
  height: 16rem;

  .appliance-quantity-card__icon-container {
    height: 4rem;
    width: 100%;
    margin-bottom: 1rem;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .appliance-quantity-card__title {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// ** Appliance Card End **\\

// ** Map Start **\\

.map__search-box {
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  width: 62%;
  font-size: 0.9rem;
}

@media (min-width: 992px) {
  .map__search-box {
    width: 35%;
  }
}

.map__location-btn {
  margin-right: 10px;
}

// ** Map End **\\

// Spinner Effect
.effect-1,
.effect-2,
.effect-3 {
  border-left-color: $primary !important;
}

// Banners Background Overlay
.bg-gradient-overlay {
  background: #000000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  pointer-events: none;
}

// Modal
.modal-title {
  color: $primary;
  margin-bottom: 0;
  font-weight: bolder;
}

// disabled inputs fixes
.disabled-input-icon-bg {
  background-color: #efefef !important;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill:disabled,
textarea:-webkit-autofill:disabled,
select:-webkit-autofill:disabled {
  box-shadow: 0 0 0 30px #efefef inset !important;
  -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
}

// Skeleton Loading
.react-loading-skeleton {
  line-height: unset !important;
}

// Solar Info Card
.solar-info-card__info-span {
  width: 20px;
  height: 20px;
  background-color: $green;
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Select
.select__info-span {
  margin-left: auto;
  padding: 2px;
  background-color: $green;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

// user profile
.user-profile__tabs {
  .nav-item {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #fbf1e1;
    }
    .nav-link.active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}

// this is for Wizard component
@media (max-width: 992px) {
  .bs-stepper-header {
    .step {
      .bs-stepper-label {
        display: none !important;
      }
    }
    .line {
      display: block !important;
    }
  }
}

// React Numeric
.react-numeric-input {
  width: 7rem;
  position: relative;
  margin-top: 0.75rem;
  input {
    width: 90%;
    height: 26px !important;
    margin-top: 0 !important;
    background-color: #f6f6f6 !important;
    border: 0 !important;
    outline: 0 !important;
    border-radius: 0.5rem !important;
    transform: translate(0, 10%) !important;
  }
  b {
    height: 26px !important;
    background-color: $primary !important;
    padding: 0.7rem !important;
    border-radius: 0.3rem !important;
    border: 0 !important;
    cursor: pointer !important;
    i {
      background-color: white !important;
    }
  }
}
.modal-open {
  padding-right: 0 !important;
}

@media (max-width: 648px) {
  .content-header .breadcrumb {
    display: flex !important;
  }
}

// add appliance card img
.add-appliance-card__img {
  height: 140px;
  width: 120px;
}

// Hide slider input steps and keep only max and min values
.noUi-value-sub,
.noUi-marker-sub {
  display: none !important;
}

// register form height
.auth-register__form {
  height: 900px;
}

@media (max-width: 576px) {
  .auth-register__form {
    height: 950px;
  }
}

// solar info card height
.solar-info-card__desc {
  height: 40px;
}

// contact us page google map height
@media (max-width: 991px) {
  .contact-us-details__google-map {
    height: 250px;
  }
}

// ** Switch Language
.sidebar__menu > div.main-menu.menu-fixed {
  position: relative !important;
}

.lang-list.dropdown-menu[data-popper-placement]:not([data-popper-placement^="top-"]) {
  top: 100% !important;

  @media (max-width: 767px) {
    top: 50% !important;
  }
}

// ** Dashboard
.dashboard-card_desc {
  font-size: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
