//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$primary: #ffab31;
$secondary: #696663;
$green: #77AB49;
$body-bg: #fff;
$content-padding: 0;
$position-values: (
  0: 0%,
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  35: 35%,
  50: 50%,
  75: 75%,
  100: 100%,
);
$enable-negative-margins: true;
$line-height-lg: 1.7;
$modal-header-border-width: 1px;
$modal-header-border-color: rgba(34, 41, 47, 0.05);
